import { toHex } from '@/utils'

export const ChainId = {
  POLYGON: toHex(137),
  GODWOKEN_TESTNET: toHex(71401),
  GODWOKEN_MAINNET: toHex(71402),
}

export const rpcUrls = {
  [ChainId.POLYGON]: ['https://rpc-mainnet.matic.quiknode.pro'],
  [ChainId.GODWOKEN_TESTNET]: ['https://godwoken-testnet-v1.ckbapp.dev'],
  [ChainId.GODWOKEN_MAINNET]: ['https://v1.mainnet.godwoken.io/rpc'],
}

export const blockExplorerUrls = {
  [ChainId.POLYGON]: ['https://polygonscan.com'],
  [ChainId.GODWOKEN_TESTNET]: ['https://gw-explorer.nervosdao.community'],
  [ChainId.GODWOKEN_MAINNET]: ['https://v1.gwscan.com/'],
}

export const networkName = {
  [ChainId.POLYGON]: 'Polygon Mainnet',
  [ChainId.GODWOKEN_TESTNET]: 'Godwoken Testnet L2',
  [ChainId.GODWOKEN_MAINNET]: 'Godwoken Mainnet L2',
}

export const networkTokenName = {
  [ChainId.POLYGON]: 'Matic',
  [ChainId.GODWOKEN_TESTNET]: 'Ethereum',
  [ChainId.GODWOKEN_MAINNET]: 'Ethereum',
}

export const networkToken = {
  [ChainId.POLYGON]: 'ETH',
  [ChainId.GODWOKEN_TESTNET]: 'pCKB',
  [ChainId.GODWOKEN_MAINNET]: 'pCKB',
}
