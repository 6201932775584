import React, { createContext, useEffect, useState, useContext } from 'react'
import { useWeb3 } from '@/context/web3'

const ServerContext = createContext({
  isRegistered: '',
  isNonceGenerated: false,
  receiverData: {},
  userData: {},
  registerationRes: {},
  generateNonce: (userData) => {},
  findUser: (findAccount, forReceiver) => {},
  registerUser: (userData) => {},
})

export const useServer = () => useContext(ServerContext)

const ServerProvider = ({ children }) => {
  const URL = process.env.REACT_APP_SERVER_URL || 'http://localhost:4000'
  const { userAddress } = useWeb3()
  const [userData, setUserData] = useState({})
  const [isNonceGenerated, setIsNonceGenerated] = useState(false)
  const [isRegistered, setIsRegistered] = useState('')
  const [findAddress, setFindAddress] = useState('')
  const [findReceiverAddress, setFindReceiverAddress] = useState('')
  const [receiverData, setReceiverData] = useState({})
  const [registerationRes, setRegisterationRes] = useState({})

  const findUser = (findAccount = userAddress, forReceiver = false) => {
    if (forReceiver) {
      // to clear old receiver's data from app as the new receiver is to be fetched
      // if (Object.keys(receiverData).includes('publicKey') && receiverData.address === findAccount) {
      //   return
      // }
      setFindReceiverAddress(findAccount)
    } else {
      setFindAddress(findAccount)
    }
  }

  const handleUserFound = (user, userAddress) => {
    // console.log('User data ---->', user)
    if (findAddress === userAddress && !user.error) {
      setIsRegistered(!user.error && user.publicKey !== '' ? 'true' : 'false')
      setIsNonceGenerated(!user.error && user.nonce > 0 ? true : false)

      // console.log('Found this address', user.address, findAddress)
      // if sender is the receiver we do not set their data
      setUserData(!user.error ? user : {})
    } else {
      setReceiverData(!user.error ? user : {})
    }
  }
  // console.log('User data ---->', userData)

  // ? Generate user nonce
  const generateNonce = (userData) => {
    // console.log('----> Generate nonce called', userData)
    if (userData) {
      try {
        fetch(URL + '/generate-nonce', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: userData,
        })
          .then((res) => res.json())
          .then((data) => !data.error && setUserData(data))
        setIsNonceGenerated(true)
      } catch (error) {
        console.error(error)
      }
    }
  }

  // ? Register user with backend
  const registerUser = (userData) => {
    // console.log('------->  User Auth Called', userData)
    if (userData.address !== '' && userData.signature !== '') {
      fetch(URL + '/register', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: userData,
      })
        .then((res) => res.json())
        .then((data) => setRegisterationRes(data))
        .catch((err) => console.log(err))
      setIsRegistered('true')
    } else {
      console.log('User registerUser failed...')
    }
  }

  // ! Not used now as we don't clear nonceSignature
  // ? Logout user from app
  // const logout = () => {
  //   console.log('-------> Logout Called')
  //   if (isRegistered && isNonceGenerated) {
  //     fetch(URL + '/logout', {
  //       method: 'POST',
  //       headers: {
  //         Accept: 'application/json',
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({
  //         address: userAddress,
  //       }),
  //     })
  //       .then((res) => res.json())
  //       .catch((err) => console.log(err))
  //   } else {
  //     console.log('User logout failed...')
  //   }
  // }

  // * Get user details from his address

  // useEffect(() => {
  //   if (findAddress?.length >= 40) {
  //     fetch(URL + '/user/' + findAddress)
  //       .then((res) => res.json())
  //       .then((user) => handleUserFound(user, userAddress))
  //   }
  // }, [userAddress, URL, findAddress])

  React.useMemo(() => {
    if (findAddress?.length >= 40) {
      fetch(URL + '/user/' + findAddress)
        .then((res) => res.json())
        .then((user) => handleUserFound(user, userAddress))
    }
  }, [userAddress, URL, findAddress])

  useEffect(() => {
    if (findReceiverAddress?.length >= 40) {
      fetch(URL + '/user/' + findReceiverAddress)
        .then((res) => res.json())
        .then((user) => setReceiverData(!user.error ? user : {}))
    }
  }, [URL, findReceiverAddress])

  // * Get user details by calling findUser
  useEffect(() => {
    findUser()
  }, [userAddress /* findAddress */])

  return (
    <ServerContext.Provider
      value={{
        isRegistered,
        isNonceGenerated,
        generateNonce,
        findUser,
        userData,
        receiverData,
        registerUser,
        registerationRes,
      }}
    >
      {children}
    </ServerContext.Provider>
  )
}

const LayoutContext = createContext({
  isMobile: false,
})

export const useLayout = () => useContext(LayoutContext)

export const LayoutProvider = ({ children }) => {
  const [isMobile, setIsMobile] = useState(process.browser ? window.innerWidth < 768 : false)

  const onResize = () => {
    if (window.innerWidth < 768 && !isMobile) {
      setIsMobile(true)
    } else if (window.innerWidth >= 768 && isMobile) {
      setIsMobile(false)
    }
  }

  useEffect(onResize, [])
  useEffect(() => {
    window.addEventListener('resize', onResize)

    const unsubscribe = () => {
      window.removeEventListener('resize', onResize)
    }
    return unsubscribe
  })

  return <LayoutContext.Provider value={{ isMobile }}>{children}</LayoutContext.Provider>
}

export default ServerProvider
