import React from 'react'
import { ethers } from 'ethers'
import { createContext, useContext, useEffect, useState } from 'react'
import { blockExplorerUrls, rpcUrls, networkToken, networkTokenName, networkName, ChainId } from '@/constants'
import { formatEther, parseEther } from 'ethers/lib/utils'

const chainId = process.env.REACT_APP_MAINNET ? ChainId.GODWOKEN_MAINNET : ChainId.GODWOKEN_TESTNET
const defaultProvider = new ethers.providers.JsonRpcProvider(rpcUrls[chainId][0])

// to export context out of the file
const Web3Context = createContext({
  provider: defaultProvider,
  initWallet: () => {},
  logoutWallet: () => {},
  signer: null,
  wallet: null,
  userAddress: '',
  accountBalance: '',
})

export const useWeb3 = () => useContext(Web3Context)

const Web3Provider = ({ children }) => {
  const [signer, setSigner] = useState(null)
  const [wallet, setWallet] = useState(null)
  const [provider, setProvider] = useState(defaultProvider)
  const [userAddress, setUserAddress] = useState('')
  const [walletConnected, setWalletConnected] = useState(false)
  const [accountBalance, setAccountBalance] = useState('0')

  const handleAccountChange = ([account]) => {
    window.location.reload(false)
    setUserAddress(window.ethereum.selectedAddress || account)
  }

  const prepareWallet = async (wallet) => {
    console.log('--> Wallet:', wallet)
    try {
      if (wallet.chainId !== chainId) {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain', // this fallbacks to wallet_switchEthereumChain
          params: [
            {
              chainId: chainId,
              chainName: networkName[chainId],
              rpcUrls: rpcUrls[chainId],
              blockExplorerUrls: blockExplorerUrls[chainId],
              nativeCurrency: {
                name: networkTokenName[chainId],
                symbol: networkToken[chainId],
                decimals: 18,
              },
            },
          ],
        })
      }

      const [account] = await window.ethereum.request({
        method: 'eth_requestAccounts',
      })

      const currentAddress = window.ethereum.selectedAddress || account
      setWallet(wallet)
      setWalletConnected(true)
      setUserAddress(currentAddress)
      setProvider(new ethers.providers.Web3Provider(wallet))
    } catch (err) {
      console.error(err.message)
    }
  }

  const initWallet = async () => {
    const provider = window.ethereum
    if (!provider) return window.alert('Please install Metamask to use the app!')

    window.ethers = ethers
    window.parseEther = parseEther
    window.formatEther = formatEther
    setWalletConnected(true)

    if (provider) {
      await prepareWallet(provider).then(() => getBalance())

      const callBack = () => {
        window.ethereum.removeAllListeners('chainChanged')
        window.ethereum.removeAllListeners('accountsChanged')
      }

      window.ethereum.on('chainChanged', () => window.location.reload())
      window.ethereum.on('accountsChanged', handleAccountChange)
      return callBack
    }
  }

  const logoutWallet = async () => {
    setWallet(null)
    setSigner(null)
    setProvider(defaultProvider)
    setWalletConnected(false)
    setUserAddress('')

    // * To logout metamask wallet account by req new account's login
    // const accounts = await window.ethereum
    //   .request({
    //     method: 'wallet_requestPermissions',
    //     params: [
    //       {
    //         eth_accounts: {},
    //       },
    //     ],
    //   })
    //   .then(() =>
    //     window.ethereum.request({
    //       method: 'eth_requestAccounts',
    //     })
    //   )
    // const account = accounts[0]
  }

  const getBalance = async () => {
    if (window?.ethereum?.selectedAddress) {
      const providers = new ethers.providers.JsonRpcProvider(rpcUrls[chainId][0])
      const balance = await providers.getBalance(window.ethereum.selectedAddress)
      setAccountBalance(formatEther(balance))
    } else {
      return
    }
  }

  useEffect(() => {
    getBalance()
  }, [userAddress])

  useEffect(() => {
    if (!!userAddress) {
      setSigner(provider.getSigner(userAddress))
    }
  }, [userAddress, provider])

  // Comment this line to disable automatic popup
  useEffect(() => {
    if (!window.ethereum) {
      initWallet()
    } else {
      window?.ethereum?.selectedAddress !== null &&
        window?.ethereum?.chainId === ChainId.GODWOKEN_TESTNET &&
        initWallet()
    }
  }, [])

  // Return wrapping children of web3 context
  return (
    <Web3Context.Provider
      value={{ userAddress, wallet, walletConnected, provider, accountBalance, signer, initWallet, logoutWallet }}
    >
      {children}
    </Web3Context.Provider>
  )
}

export default Web3Provider
